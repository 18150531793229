<div class="navbar-container">
    <a routerLink="/" class="logo-container">
        <img class="logo" *ngIf="config.get('branding.logo_light')" [src]="config.getBaseUrl(true) + config.get('branding.logo_light')">
        <div class="text-logo" *ngIf="!config.get('branding.logo_light')">{{config.get('branding.site_name')}}</div>
    </a>

    <button class="no-style toggle-sidebar-button" mat-icon-button *ngIf="showToggleButton" (click)="toggleButtonClick.emit()">
        <mat-icon svgIcon="menu"></mat-icon>
    </button>

    <ng-content></ng-content>

    <custom-menu [position]="menuPosition"></custom-menu>

    <logged-in-user-widget>
        <a mat-menu-item [routerLink]="item.route" *ngFor="let item of config.get('vebto.navbar.dropdownItems')">
            <mat-icon [svgIcon]="item.icon"></mat-icon>
            <span>{{item.name}}</span>
        </a>
    </logged-in-user-widget>
</div>