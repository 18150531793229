var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { MatDialog } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var Modal = /** @class */ (function () {
    function Modal(dialog) {
        this.dialog = dialog;
    }
    Modal.prototype.open = function (component, data, config) {
        if (data === void 0) { data = {}; }
        if (config === void 0) { config = {}; }
        if (!data)
            data = {};
        if (typeof config === 'string')
            config = { panelClass: config };
        if (!Array.isArray(config.panelClass))
            config.panelClass = [config.panelClass];
        config.panelClass.push('be-modal');
        return this.dialog.open(component, __assign({}, config, { data: data }));
    };
    Modal.prototype.show = function (component, data) {
        if (data === void 0) { data = {}; }
        return this.open(component, data);
    };
    Modal.ngInjectableDef = i0.defineInjectable({ factory: function Modal_Factory() { return new Modal(i0.inject(i1.MatDialog)); }, token: Modal, providedIn: "root" });
    return Modal;
}());
export { Modal };
