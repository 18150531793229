import { Router } from "@angular/router";
import { Settings } from "../config/settings.service";
import { CustomPageComponent } from "./custom-page/custom-page.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../config/settings.service";
var CustomHomepage = /** @class */ (function () {
    function CustomHomepage(router, settings) {
        this.router = router;
        this.settings = settings;
        this.defaultComponents = [
            { path: 'login', redirectTo: '/login', pathMatch: 'full' },
            { path: 'register', redirectTo: '/register', pathMatch: 'full' },
        ];
    }
    CustomHomepage.prototype.select = function (userRoutes) {
        if (userRoutes === void 0) { userRoutes = []; }
        this.defaultComponents = this.defaultComponents.concat(userRoutes);
        var type = this.settings.get('homepage.type', 'default'), value = this.settings.get('homepage.value');
        switch (type) {
            case 'default':
                return;
            case 'component':
                return this.setComponentAsHomepage(value);
            case 'page':
                return this.setCustomPageAsHomepage(value);
        }
    };
    CustomHomepage.prototype.getComponents = function () {
        return this.defaultComponents;
    };
    CustomHomepage.prototype.setComponentAsHomepage = function (name) {
        var route = this.defaultComponents.find(function (comp) { return comp.path === name; });
        this.addRoute(Object.assign({}, route));
    };
    CustomHomepage.prototype.setCustomPageAsHomepage = function (id) {
        var route = { component: CustomPageComponent, data: { id: id } };
        this.addRoute(route);
    };
    CustomHomepage.prototype.addRoute = function (route) {
        var parent = this.getParentHomeRoute();
        route = this.prepareRoute(route);
        //use child routes if parent exists, otherwise use base router config
        var routes = parent ? parent.children : this.router.config;
        //remove already existing home route
        var i = routes.findIndex(function (route) { return route.path === ''; });
        //start new route specified by user
        if (i > -1) {
            routes[i] = route;
        }
        else {
            routes.unshift(route);
        }
    };
    CustomHomepage.prototype.getParentHomeRoute = function () {
        return this.router.config.find(function (route) {
            return route.data && route.data.name === 'parent-home-route';
        });
    };
    CustomHomepage.prototype.prepareRoute = function (route) {
        route.path = '';
        if (!route.data)
            route.data = {};
        route.data.name = 'home';
        return route;
    };
    CustomHomepage.ngInjectableDef = i0.defineInjectable({ factory: function CustomHomepage_Factory() { return new CustomHomepage(i0.inject(i1.Router), i0.inject(i2.Settings)); }, token: CustomHomepage, providedIn: "root" });
    return CustomHomepage;
}());
export { CustomHomepage };
